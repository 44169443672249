import React from "react"
import Layout from "../components/Layout"
import * as styles from "../styles/mjml.module.css"
import MJML from "../../static/images/mjml.svg"
import Html from "../../static/images/html.png"

export default function Mjml() {
  return (
    <Layout>
      <div className={styles.devIcons}>
        <img src={MJML} alt="mjml" height="60px" width="auto"></img>
        <img src={Html} alt="illustrator" height="60px" width="auto"></img>
      </div>
      <section className={styles.header}>
        <div className={styles.mainText}>
          <h1>UNDER CONSTRUCTION</h1>
        </div>
      </section>
    </Layout>
  )
}
